export function hasActivePlan(user) {
  if (!user) return false;

  // If user has had a plan before (trial or paid), they should go to the app
  if (user.hasHadActivePlan || user.hasHadTrial) return true;

  // For new users, check if they have selected a plan
  const hasPlan = user.planId && user.durationId;
  if (!hasPlan) return false;

  // Check if user has a valid subscription status
  const hasValidStatus = ['active', 'trialing', 'inactive'].includes(user.subscriptionStatus);

  return hasPlan && hasValidStatus && user.isActive;
}
