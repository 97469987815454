export const faqs = [
  {
    question: 'O que é o Licitou?',
    answer:
      'O Licitou é uma plataforma avançada de busca e gerenciamento de licitações que utiliza inteligência artificial para encontrar as melhores oportunidades para sua empresa. Nosso sistema busca diariamente novas licitações em todo o Brasil, oferecendo recomendações personalizadas baseadas no perfil do seu negócio.',
  },
  {
    question: 'Como funciona a avaliação gratuita?',
    answer:
      'Você tem 14 dias para testar todas as funcionalidades do Licitou sem compromisso. Não é necessário cartão de crédito para começar. Ao final do período, você pode escolher um plano que melhor atenda suas necessidades.',
  },
  {
    question: 'Preciso de cartão de crédito para começar a avaliação gratuita?',
    answer:
      'Não! Você pode começar sua avaliação gratuita de 14 dias sem precisar cadastrar cartão de crédito. Basta criar sua conta e começar a usar todas as funcionalidades do plano escolhido imediatamente.',
  },
  {
    question: 'Quais estados e portais são cobertos pelo Licitou?',
    answer:
      'O Licitou cobre todos os 27 estados, monitorando os principais portais de compras públicas do Brasil. Isso inclui: ComprasNET (Federal), Portal Nacional de Compras Públicas (PNCP), Portal de Compras Públicas (PCP), BLL Compras, BNC Compras, BBMNET, Compras BR, LicitaNET, Licitar Digital, Banrisul, DOU, Licitacon, Licitações-E / BB, ProDESP, além de diversos outros portais municipais e estaduais. Nossa cobertura é constantemente expandida para garantir o máximo de oportunidades para nossos clientes.',
  },
  {
    question: 'Posso usar todas as funcionalidades durante a avaliação gratuita?',
    answer:
      'Sim! Durante os 14 dias de avaliação, você tem acesso completo a todas as funcionalidades do plano escolhido, incluindo busca inteligente, análise de editais com IA, gerenciamento de documentos e suporte especializado.',
  },
  {
    question: 'Quais são os principais benefícios de usar o Licitou?',
    answer:
      'O Licitou oferece diversos benefícios, incluindo: economia de tempo na busca por licitações, redução de erros com gerenciamento centralizado de documentos, aumento de oportunidades em todo o Brasil, tomada de decisão informada com análise de IA, organização e eficiência com um painel único de controle, aumento da competitividade e conformidade com a legislação atualizada.',
  },
  {
    question: 'Quais são os principais recursos do Licitou?',
    answer:
      'O Licitou oferece uma gama completa de recursos, incluindo: busca inteligente de licitações com filtros avançados, análise de editais com IA, gerenciamento de documentos na nuvem, painel de controle para acompanhamento de licitações, alertas personalizados e suporte especializado via chat ao vivo.',
  },
  {
    question: 'Como o Licitou ajuda a economizar tempo?',
    answer:
      'Nossa plataforma automatiza a busca e análise de editais, permitindo que você foque nas oportunidades mais relevantes para seu negócio. Além disso, o gerenciamento centralizado de documentos e alertas de vencimento minimizam o risco de desclassificação por problemas documentais.',
  },
  {
    question: 'Como o Licitou aumenta as chances de sucesso nas licitações?',
    answer:
      'O Licitou fornece acesso a mais oportunidades em todo o Brasil, análise detalhada de editais com IA, e gerenciamento eficiente de todo o processo. Isso permite que sua empresa se prepare melhor e aumente suas chances de sucesso, independentemente do seu tamanho ou setor de atuação.',
  },
  {
    question: 'O Licitou oferece suporte aos usuários?',
    answer:
      'Sim, oferecemos suporte especializado via chat ao vivo durante o horário comercial. Além disso, disponibilizamos uma base de conhecimento com tutoriais e melhores práticas para ajudar você a aproveitar ao máximo nossa plataforma.',
  },
];
