import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { create } from 'zustand';
import { auth } from '../config/firebase';
import { getToken, loadUserData } from '../services/api';
import { hasActivePlan } from '../utils';

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'Este e-mail já está em uso. Por favor, use outro e-mail ou faça login.';
    case 'auth/invalid-email':
      return 'O e-mail fornecido é inválido. Por favor, verifique e tente novamente.';
    case 'auth/weak-password':
      return 'A senha é muito fraca. Use uma senha mais forte.';
    case 'auth/user-not-found':
      return 'Usuário não encontrado. Verifique seu e-mail ou crie uma nova conta.';
    case 'auth/wrong-password':
      return 'Senha incorreta. Por favor, tente novamente.';
    case 'auth/too-many-requests':
      return 'Muitas tentativas de login. Por favor, tente novamente mais tarde.';
    case 'auth/popup-closed-by-user':
      return 'O login foi cancelado. Por favor, tente novamente.';
    default:
      return 'Ocorreu um erro durante a autenticação. Por favor, tente novamente.';
  }
};

const useAuthStore = create((set, get) => ({
  user: null,
  userData: null,
  loading: true,
  error: null,
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  getAppRedirectUrl: async (token, newUser = false, path = '/') => {
    let url = `https://app.licitou.com.br${path}?token=${token}${newUser ? '&onboarding=true' : ''}`;
    return url;
  },
  getRedirectUrl: async (newUser = false) => {
    const user = auth.currentUser;
    let url = '/planos';
    try {
      if (user) {
        const response = await getToken();
        if (response && response.token) {
          const userData = await get().getUserData();
          const hasActiveSubscription = hasActivePlan(userData);
          if (hasActiveSubscription) {
            url = get().getAppRedirectUrl(response.token, newUser);
          }
        }
      }
      return url;
    } catch (error) {
      console.error('Error getting redirect URL:', error);
      return url;
    }
  },
  initializeAuth: () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        set({ user: user, loading: false });
        get().getUserData(user);
      } else {
        set({ user: null, loading: false });
      }
    });
  },
  getUserData: async () => {
    if (get().userData) {
      return get().userData;
    }
    const data = await loadUserData().catch((error) => {
      console.error('Error loading user data:', error);
    });
    if (data) {
      set({ userData: data });
    }
    return data;
  },
  signUpWithEmail: async (email, password) => {
    set({ loading: true, error: null });
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      set({ user: userCredential.user, loading: false });
      return userCredential.user;
    } catch (error) {
      set({ error: getErrorMessage(error.code), loading: false });
      return null;
    }
  },
  signInWithGoogle: async () => {
    set({ loading: true, error: null });
    const authProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, authProvider);
      set({ user: result.user, loading: false });
    } catch (error) {
      set({ error: getErrorMessage(error.code), loading: false });
    }
  },
  signInWithEmail: async (email, password) => {
    set({ loading: true, error: null });
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      set({ user: userCredential.user, loading: false });
    } catch (error) {
      set({ error: getErrorMessage(error.code), loading: false });
    }
  },
  signOut: async () => {
    set({ loading: true, error: null });
    try {
      await signOut(auth);
      set({ user: null, loading: false });
    } catch (error) {
      set({ error: getErrorMessage(error.code), loading: false });
    }
  },
}));

export default useAuthStore;
