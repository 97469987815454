import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDEXY-F6cyhkXj6kSFosnm7s5ktV2P5-_k',
  authDomain: 'licitou.com.br',
  projectId: 'licitou-edd06',
  storageBucket: 'licitou-edd06.appspot.com',
  messagingSenderId: '848402392913',
  appId: '1:848402392913:web:e06a13ead24e78026c5570',
  measurementId: 'G-5FBWXM6Q5V',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { analytics, auth, logEvent };

export default app;
