export function smoothScroll(e, target) {
  // console.log('Smooth scroll called', e, target);
  if (e) e.preventDefault();
  const element = document.querySelector(target);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  } else {
    // console.log('Target element not found:', target);
  }
}
