export default [
  {
    stars: 5,
    profileImageSrc:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
    heading: 'Aumentamos Nossa Participação em Licitações',
    quote:
      'Com o Licitou, conseguimos expandir nossa atuação em licitações públicas. A busca inteligente e a análise de editais com IA nos ajudaram a encontrar e avaliar oportunidades de forma muito mais eficiente.',
    customerName: 'Maria Silva',
    customerTitle: 'Diretora, Construtora ABC',
  },
  {
    stars: 5,
    profileImageSrc:
      'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',
    heading: 'Economia de Tempo e Redução de Erros',
    quote:
      'O gerenciamento centralizado de documentos e os alertas personalizados do Licitou nos ajudaram a reduzir erros e economizar um tempo precioso. Agora podemos focar em preparar propostas competitivas.',
    customerName: 'João Oliveira',
    customerTitle: 'Gerente de Vendas, Tech Solutions Ltda.',
  },
];
