import { analytics, logEvent } from '../config/firebase';

const LANDING_PREFIX = 'landing_';

export const useAnalytics = () => {
  const logAnalyticsEvent = (eventName, eventParams = {}) => {
    try {
      const prefixedEventName = `${LANDING_PREFIX}${eventName}`;
      const enhancedParams = {
        ...eventParams,
        source: 'landing_page',
        environment: process.env.NODE_ENV,
      };

      logEvent(analytics, prefixedEventName, enhancedParams);
    } catch (error) {
      console.error('Analytics error:', error);
    }
  };

  const trackPageView = (pageName) => {
    logAnalyticsEvent('page_view', {
      page_title: pageName,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  };

  const trackButtonClick = (buttonName) => {
    logAnalyticsEvent('button_click', {
      button_name: buttonName,
    });
  };

  return {
    logAnalyticsEvent,
    trackPageView,
    trackButtonClick,
  };
};
