import Footer from 'components/footers/FiveColumnWithInputForm.js';
import Header from 'components/headers/Header.js';
import { SectionHeading } from 'components/misc/Headings';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { useAnalytics } from 'hooks/useAnalytics';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default function PrivacyPolicyPage({ headingText = 'Política de Privacidade' }) {
  const { trackPageView } = useAnalytics();

  useEffect(() => {
    trackPageView('Privacy Policy');
  }, []);

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              Esta Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta,
              uso e divulgação de suas informações quando você usa o Serviço e informa sobre seus
              direitos de privacidade e como a lei protege você.
            </p>

            <p>
              Usamos seus dados pessoais para fornecer e melhorar o Serviço. Ao usar o Serviço, você
              concorda com a coleta e uso de informações de acordo com esta Política de Privacidade.
            </p>

            <h1>Interpretação e Definições</h1>
            <h2>Interpretação</h2>
            <p>
              As palavras cujas letras iniciais são maiúsculas têm significados definidos nas
              seguintes condições.
            </p>
            <p>
              As seguintes definições terão o mesmo significado, independentemente de aparecerem no
              singular ou no plural.
            </p>

            <h2>Definições</h2>
            <p>Para os fins desta Política de Privacidade:</p>
            <ul>
              <li>
                <p>
                  <strong>Você</strong> significa o indivíduo que acessa ou usa o Serviço, ou a
                  empresa, ou outra entidade legal em nome da qual tal indivíduo está acessando ou
                  usando o Serviço, conforme aplicável.
                </p>
              </li>
              <li>
                <p>
                  <strong>Empresa</strong> (referida como &quot;a Empresa&quot;, &quot;Nós&quot;,
                  &quot;Nos&quot; ou &quot;Nosso&quot; neste Acordo) refere-se a Licitou.
                </p>
              </li>
              <li>
                <strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou
                está sob controle comum com uma parte, onde &quot;controle&quot; significa
                propriedade de 50% ou mais das ações, participação acionária ou outros títulos com
                direito a voto para eleição de diretores ou outra autoridade de gestão.
              </li>
              <li>
                <strong>Conta</strong> significa uma conta única criada para você acessar nosso
                Serviço ou partes de nosso Serviço.
              </li>
              <li>
                <strong>Website</strong> refere-se a Licitou, acessível a partir de
                https://licitou.com.br
              </li>
              <li>
                <strong>Serviço</strong> refere-se ao Website.
              </li>
              <li>
                <strong>País</strong> refere-se a: Brasil
              </li>
              <li>
                <p>
                  <strong>Prestador de Serviços</strong> significa qualquer pessoa física ou
                  jurídica que processa os dados em nome da Empresa. Refere-se a empresas ou
                  indivíduos terceirizados contratados pela Empresa para facilitar o Serviço, para
                  fornecer o Serviço em nome da Empresa, para realizar serviços relacionados ao
                  Serviço ou para ajudar a Empresa a analisar como o Serviço é usado.
                </p>
              </li>
              <li>
                <strong>Serviço de Mídia Social de Terceiros</strong> refere-se a qualquer site ou
                qualquer site de rede social através do qual um usuário pode fazer login ou criar
                uma conta para usar o Serviço.
              </li>
              <li>
                <p>
                  <strong>Dados Pessoais</strong> são quaisquer informações que se relacionem com um
                  indivíduo identificado ou identificável.
                </p>
              </li>
              <li>
                <strong>Cookies</strong> são pequenos arquivos que são colocados no seu computador,
                dispositivo móvel ou qualquer outro dispositivo por um site, contendo os detalhes do
                seu histórico de navegação nesse site entre seus muitos usos.
              </li>
              <li>
                <strong>Dados de Uso</strong> referem-se a dados coletados automaticamente, gerados
                pelo uso do Serviço ou da própria infraestrutura do Serviço (por exemplo, a duração
                de uma visita à página).
              </li>
            </ul>

            <h1>Coletando e Usando Seus Dados Pessoais</h1>
            <h2>Tipos de Dados Coletados</h2>

            <h3>Dados Pessoais</h3>
            <p>
              Enquanto usa nosso Serviço, podemos pedir que você nos forneça certas informações de
              identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo.
              Informações de identificação pessoal podem incluir, mas não estão limitadas a:
            </p>
            <ul>
              <li>Endereço de e-mail</li>
              <li>Nome e sobrenome</li>
              <li>Número de telefone</li>
              <li>Endereço, Estado, Província, CEP/Código Postal, Cidade</li>
              <li>Dados de Uso</li>
            </ul>

            <h3>Dados de Uso</h3>
            <p>Os Dados de Uso são coletados automaticamente ao usar o Serviço.</p>

            <h2>Uso de Seus Dados Pessoais</h2>
            <p>A Empresa pode usar Dados Pessoais para os seguintes fins:</p>
            <ul>
              <li>
                <strong>Para fornecer e manter nosso Serviço</strong>, incluindo monitorar o uso de
                nosso Serviço.
              </li>
              <li>
                <strong>Para gerenciar sua Conta:</strong> para gerenciar seu registro como usuário
                do Serviço. Os Dados Pessoais que você fornece podem dar acesso a diferentes
                funcionalidades do Serviço que estão disponíveis para você como usuário registrado.
              </li>
              <li>
                <strong>Para a execução de um contrato:</strong> o desenvolvimento, conformidade e
                execução do contrato de compra dos produtos, itens ou serviços que você comprou ou
                de qualquer outro contrato conosco através do Serviço.
              </li>
              <li>
                <strong>Para contatá-lo:</strong> Para contatá-lo por e-mail, chamadas telefônicas,
                SMS ou outras formas equivalentes de comunicação eletrônica, como notificações push
                de um aplicativo móvel sobre atualizações ou comunicações informativas relacionadas
                às funcionalidades, produtos ou serviços contratados, incluindo as atualizações de
                segurança, quando necessário ou razoável para sua implementação.
              </li>
              <li>
                <strong>Para fornecer a você</strong> notícias, ofertas especiais e informações
                gerais sobre outros bens, serviços e eventos que oferecemos que são semelhantes aos
                que você já comprou ou perguntou, a menos que você tenha optado por não receber tais
                informações.
              </li>
              <li>
                <strong>Para gerenciar seus pedidos:</strong> Para atender e gerenciar seus pedidos
                para nós.
              </li>
              <li>
                <strong>Para transferências de negócios:</strong> Podemos usar suas informações para
                avaliar ou conduzir uma fusão, alienação, reestruturação, reorganização, dissolução
                ou outra venda ou transferência de alguns ou todos os nossos ativos, seja como uma
                empresa em funcionamento ou como parte de uma falência, liquidação ou processo
                semelhante, em que os Dados Pessoais mantidos por nós sobre os usuários do nosso
                Serviço estão entre os ativos transferidos.
              </li>
              <li>
                <strong>Para outros fins:</strong> Podemos usar suas informações para outros fins,
                como análise de dados, identificação de tendências de uso, determinação da eficácia
                de nossas campanhas promocionais e para avaliar e melhorar nosso Serviço, produtos,
                serviços, marketing e sua experiência.
              </li>
            </ul>

            <h2>Retenção de Seus Dados Pessoais</h2>
            <p>
              A Empresa reterá seus Dados Pessoais apenas pelo tempo necessário para os fins
              estabelecidos nesta Política de Privacidade. Reteremos e usaremos seus Dados Pessoais
              na medida necessária para cumprir nossas obrigações legais (por exemplo, se formos
              obrigados a reter seus dados para cumprir com as leis aplicáveis), resolver disputas e
              fazer cumprir nossos acordos e políticas legais.
            </p>
            <p>
              A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Uso
              geralmente são retidos por um período de tempo mais curto, exceto quando esses dados
              são usados para fortalecer a segurança ou para melhorar a funcionalidade do nosso
              Serviço, ou somos legalmente obrigados a reter esses dados por períodos de tempo mais
              longos.
            </p>

            <h2>Transferência de Seus Dados Pessoais</h2>
            <p>
              Suas informações, incluindo Dados Pessoais, são processadas nos escritórios
              operacionais da Empresa e em qualquer outro lugar onde as partes envolvidas no
              processamento estejam localizadas. Isso significa que essas informações podem ser
              transferidas para — e mantidas em — computadores localizados fora do seu estado,
              província, país ou outra jurisdição governamental onde as leis de proteção de dados
              podem diferir das leis da sua jurisdição.
            </p>
            <p>
              Seu consentimento para esta Política de Privacidade seguido pelo envio de tais
              informações representa sua concordância com essa transferência.
            </p>
            <p>
              A Empresa tomará todas as medidas razoavelmente necessárias para garantir que seus
              dados sejam tratados com segurança e de acordo com esta Política de Privacidade e
              nenhuma transferência de seus Dados Pessoais ocorrerá para uma organização ou um país,
              a menos que haja controles adequados em vigor, incluindo a segurança de seus dados e
              outras informações pessoais.
            </p>

            <h2>Divulgação de Seus Dados Pessoais</h2>
            <h3>Transações Comerciais</h3>
            <p>
              Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, seus Dados
              Pessoais poderão ser transferidos. Forneceremos aviso antes que seus Dados Pessoais
              sejam transferidos e se tornem sujeitos a uma Política de Privacidade diferente.
            </p>
            <h3>Aplicação da lei</h3>
            <p>
              Em determinadas circunstâncias, a Empresa pode ser obrigada a divulgar seus Dados
              Pessoais se exigido por lei ou em resposta a solicitações válidas de autoridades
              públicas (por exemplo, um tribunal ou uma agência governamental).
            </p>
            <h3>Outros requisitos legais</h3>
            <p>
              A Empresa pode divulgar seus Dados Pessoais na crença de boa fé de que tal ação é
              necessária para:
            </p>
            <ul>
              <li>Cumprir uma obrigação legal</li>
              <li>Proteger e defender os direitos ou propriedade da Empresa</li>
              <li>Prevenir ou investigar possíveis irregularidades em conexão com o Serviço</li>
              <li>Proteger a segurança pessoal dos usuários do Serviço ou do público</li>
              <li>Proteger contra responsabilidade legal</li>
            </ul>

            <h2>Segurança de Seus Dados Pessoais</h2>
            <p>
              A segurança de seus Dados Pessoais é importante para nós, mas lembre-se de que nenhum
              método de transmissão pela Internet ou método de armazenamento eletrônico é 100%
              seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis para proteger
              seus Dados Pessoais, não podemos garantir sua segurança absoluta.
            </p>

            <h1>Links para Outros Sites</h1>
            <p>
              Nosso Serviço pode conter links para outros sites que não são operados por nós. Se
              você clicar em um link de terceiros, será direcionado para o site desse terceiro.
              Aconselhamos fortemente que você reveja a Política de Privacidade de cada site que
              visitar.
            </p>
            <p>
              Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de
              privacidade ou práticas de sites ou serviços de terceiros.
            </p>

            <h1>Alterações a esta Política de Privacidade</h1>
            <p>
              Podemos atualizar nossa Política de Privacidade de tempos em tempos. Notificaremos
              você de quaisquer alterações publicando a nova Política de Privacidade nesta página.
            </p>
            <p>
              Avisaremos você por e-mail e/ou um aviso em destaque em nosso Serviço, antes que a
              alteração se torne efetiva e atualizaremos a data da &quot;Última atualização&quot; no
              topo desta Política de Privacidade.
            </p>
            <p>
              Você é aconselhado a revisar esta Política de Privacidade periodicamente para
              quaisquer alterações. As alterações a esta Política de Privacidade são efetivas quando
              publicadas nesta página.
            </p>

            <h1>Contate-Nos</h1>
            <p>
              Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato
              conosco:
            </p>
            <ul>
              <li>Por e-mail: suporte@licitou.com.br</li>
              <li>Visitando esta página em nosso site: https://licitou.com.br/#contact</li>
              <li>Por telefone: +55 41 988229199</li>
            </ul>

            <h2>Dados Coletados Durante o Período de Avaliação</h2>
            <p>
              Durante o período de avaliação gratuita de 14 dias, coletamos dados adicionais sobre o
              uso do serviço para melhorar nossa plataforma e personalizar sua experiência. Isso
              inclui:
            </p>
            <ul>
              <li>Padrões de uso das funcionalidades</li>
              <li>Frequência de acesso</li>
              <li>Tipos de licitações pesquisadas</li>
              <li>Interações com o suporte</li>
            </ul>

            <h1>Última Atualização</h1>
            <p>Esta política foi atualizada pela última vez em 3 de novembro de 2024.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
}
