import { motion } from 'framer-motion';
import useInView from 'helpers/useInView';
import React, { useRef } from 'react';
import tw from 'twin.macro';

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ['left', 'right'];
  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    );
  });
  return <>{childrenWithAnimation}</>;
}

function AnimatedSlideInComponent({ direction = 'left', offset = 30, children }) {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px`, triggerOnce: true });

  const x = { target: '0%' };

  if (direction === 'left') x.initial = '-150%';
  else x.initial = '150%';

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{
          x: inView ? x.target : x.initial,
        }}
        transition={{ type: 'spring', damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
}

export default function AnimationRevealPage({ disabled, children }) {
  const pageRef = useRef(null);

  return (
    <StyledDiv className="App" ref={pageRef}>
      <AnimationReveal disabled={disabled}>{children}</AnimationReveal>
    </StyledDiv>
  );
}
