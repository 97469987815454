import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default function TermsText() {
  return (
    <Text>
      <h1>CONTRATO DE USO DA PLATAFORMA LICITOU</h1>
      <p>
        Ao cadastrar-se ou assinar os serviços em nossa plataforma, a pessoa física ou jurídica,
        doravante denominada "USUÁRIO", aceita integralmente as condições e cláusulas a seguir, bem
        como a POLÍTICA DE PRIVACIDADE acessível em https://licitou.com.br/, referentes aos serviços
        oferecidos pela LICITOU GESTÃO DE SOFTWARE EM LICITAÇÃO LTDA, situada à Rua Treze de Maio,
        nº 336, Sala 33; Andar 3°; Cond. Edifício Santa Maria; Centro, Curitiba - PR, CEP:
        80020-270, CNPJ 58.264.149/0001-68, doravante referida como "LICITOU".
      </p>

      <h2>CLÁUSULA 1: TERMOS GERAIS</h2>
      <p>
        1.1. PLATAFORMA DE ACESSO: Refere-se ao conjunto de funcionalidades e serviços que o USUÁRIO
        pode acessar através de nosso site ou aplicativos, incluindo interfaces gráficas e
        programáticas como APIs. A PLATAFORMA DE ACESSO compreende todos os recursos para consulta,
        manipulação e integração de dados, além do envio de notificações via e-mail.
      </p>
      <p>
        1.2. PERÍODO DE TESTE: Acesso concedido ao USUÁRIO para uso da PLATAFORMA DE ACESSO sem
        custos por um período limitado de 14 dias.
      </p>
      <p>
        1.3. PLANO DE ASSINATURA: Cadastro que permite o acesso ao conteúdo da PLATAFORMA DE ACESSO
        mediante pagamento periódico, conforme detalhado no momento da contratação.
      </p>
      <p>
        1.4. CONTEÚDO DISPONIBILIZADO: Informações provenientes do banco de dados da PLATAFORMA DE
        ACESSO sobre licitações pré-cadastradas, com possíveis filtros de pesquisa.
      </p>
      <p>
        1.5. REQUISITOS DE ACESSO: Equipamentos como computador com acesso à internet, softwares
        atualizados e qualquer outro recurso necessário para correta utilização dos serviços.
      </p>
      <p>
        1.6. INFORMAÇÕES DE CADASTRO: Dados fornecidos pelo USUÁRIO para identificação na
        plataforma.
      </p>

      <h2>CLÁUSULA 2: OBJETO</h2>
      <p>
        2.1. O presente contrato visa regulamentar a provisão de CONTEÚDO pela LICITOU ao USUÁRIO
        através da PLATAFORMA DE ACESSO.
      </p>

      <h2>CLÁUSULA 3: CADASTRO E ADESÃO</h2>
      <p>
        3.1. A adesão aos serviços LICITOU se dará mediante assinatura ou uso do PERÍODO DE TESTE,
        com possibilidade de conversão para assinatura.
      </p>

      <h2>CLÁUSULA 4: PERÍODO DE TESTE</h2>
      <p>
        4.1. A adesão ao contrato se concretiza após o cadastro do USUÁRIO e aceitação dos termos
        aqui presentes.
      </p>
      <p>
        4.1.1. O USUÁRIO tem até 7 dias após o início do PERÍODO DE TESTE para questionar quaisquer
        informações. Decorrido este prazo sem manifestação, considera-se o contrato aceito.
      </p>
      <p>
        4.1.2. A concessão do PERÍODO DE TESTE é uma cortesia da LICITOU, passível de alteração ou
        cancelamento a qualquer momento.
      </p>
      <p>
        4.1.3. A LICITOU poderá solicitar a confirmação dos DADOS CADASTRAIS a qualquer momento.
      </p>
      <p>
        4.1.4. Caso haja inconsistências nos DADOS CADASTRAIS ou uso inadequado, a LICITOU pode
        bloquear o acesso do USUÁRIO.
      </p>
      <p>
        4.1.5. A adesão ao contrato não gera obrigação de assinatura ou pagamento. Após o PERÍODO DE
        TESTE, não haverá renovação automática ou cobrança sem consentimento do USUÁRIO.
      </p>

      <h2>CLÁUSULA 5: ASSINATURA</h2>
      <p>5.1. O USUÁRIO pode optar pela assinatura durante ou após o PERÍODO DE TESTE.</p>
      <p>
        5.2. Para converter o teste em assinatura, basta escolher uma opção de plano e forma de
        pagamento disponíveis na PLATAFORMA DE ACESSO.
      </p>
      <p>5.3. Ao assinar, o USUÁRIO seleciona livremente o método de pagamento desejado.</p>
      <p>
        5.4. Cancelamentos resultam em bloqueio ou limitação do acesso ao CONTEÚDO, até nova
        assinatura.
      </p>
      <p>
        5.5. A assinatura tem vigência a partir da confirmação do pagamento ou análise de risco do
        pagamento.
      </p>
      <p>
        5.6. A LICITOU pode, a seu critério, iniciar a vigência da assinatura como cortesia, sem
        modificar os termos deste contrato.
      </p>
      <p>
        5.7. O USUÁRIO é responsável por fornecer DADOS CADASTRAIS corretos e manter sua senha de
        acesso segura, não responsabilizando a LICITOU por perdas resultantes do uso indevido da
        senha.
      </p>
      <p>
        5.8. O USUÁRIO garante a veracidade das informações, incluindo e-mail, responsabilizando-se
        por receber comunicações da LICITOU.
      </p>
      <p>
        5.9. Inconsistências nos DADOS CADASTRAIS ou uso abusivo podem resultar em bloqueio do
        acesso e rescisão do contrato sem responsabilidade da LICITOU.
      </p>
      <p>
        5.10. O USUÁRIO pode autorizar o recebimento de comunicações e ofertas do LICITOU e/ou
        parceiros.
      </p>
      <p>
        5.11. Próximo ao vencimento, a LICITOU enviará propostas de renovação, sem obrigatoriedade
        para o USUÁRIO.
      </p>
      <p>5.12. A renovação da assinatura é opcional.</p>

      <h2>CLÁUSULA 6: OBRIGAÇÕES E RESPONSABILIDADES DO USUÁRIO</h2>
      <p>
        6.1. Cabe ao USUÁRIO dispor dos equipamentos necessários para utilizar a assinatura ou o
        período de teste, arcando com seus custos e manutenção.
      </p>
      <p>
        6.2. É responsabilidade do USUÁRIO verificar informações divulgadas e atualizações de
        CONTEÚDO.
      </p>
      <p>
        6.3. O USUÁRIO deve assegurar o correto uso de sua senha de acesso e respeitar os
        compromissos firmados na PLATAFORMA DE ACESSO.
      </p>
      <p>
        6.4. Qualquer tentativa de prejudicar a infraestrutura da LICITOU pode resultar em ações
        legais e cobrança de danos.
      </p>
      <p>
        6.5. A LICITOU não garante a funcionalidade plena de seus serviços, que dependem de fatores
        externos como acesso à internet e equipamentos adequados.
      </p>
      <p>
        6.6. O USUÁRIO deve usar a PLATAFORMA DE ACESSO para fins lícitos e conforme os limites
        estabelecidos, evitando práticas como engenharia reversa, varredura massiva, redistribuição
        de conteúdo sem autorização, e manipulação indevida de dados.
      </p>
      <p>
        6.7. O USUÁRIO não pode compartilhar seu acesso ou usar dispositivos automatizados sem
        permissão expressa da LICITOU.
      </p>
      <p>
        6.8. Atividades ilícitas na plataforma são proibidas e podem resultar em rescisão
        contratual.
      </p>

      <h2>CLÁUSULA 7: DISPONIBILIDADE E EXCLUSÃO DE RESPONSABILIDADE</h2>
      <p>
        7.1. A LICITOU compromete-se a disponibilizar o CONTEÚDO durante a vigência da assinatura,
        exceto em casos de manutenção ou eventos imprevistos.
      </p>
      <p>
        7.1.1. A disponibilidade contínua de serviços não é garantida, devido à complexidade e
        dependência de fatores externos.
      </p>
      <p>
        7.1.2. A LICITOU não se responsabiliza por perdas decorrentes de interrupções ou falhas no
        serviço.
      </p>
      <p>
        7.1.3. Falhas nas redes de telecomunicações podem afetar a disponibilidade dos serviços.
      </p>
      <p>7.1.4. Não são oferecidas garantias específicas sobre a eficácia da assinatura.</p>
      <p>
        7.2. A LICITOU é uma ferramenta de pesquisa de licitações e não garante a precisão completa
        de suas informações.
      </p>
      <p>7.3. O USUÁRIO não recebe direitos além do acesso ao CONTEÚDO.</p>
      <p>
        7.4. A LICITOU não é responsável por informações incorretas, desatualizadas ou ausentes.
      </p>
      <p>7.5. Alterações técnicas ou de conteúdo podem ser feitas a qualquer momento, sem aviso.</p>

      <h2>CLÁUSULA 8: RESPONSABILIDADE PERANTE TERCEIROS</h2>
      <p>
        8.1. O USUÁRIO manterá a LICITOU isenta de quaisquer reivindicações de terceiros decorrentes
        de violações do contrato.
      </p>
      <p>
        8.2. A LICITOU não se responsabiliza por danos relacionados à participação em licitações ou
        ao uso do CONTEÚDO.
      </p>

      <h2>CLÁUSULA 9: PAGAMENTOS E PENALIDADES</h2>
      <p>9.1. Os valores devidos devem ser pagos conforme estipulado no momento da contratação.</p>
      <p>9.2. Inadimplência pode resultar em suspensão imediata do acesso e rescisão contratual.</p>
      <p>
        9.3. O USUÁRIO concorda em arcar com custos decorrentes de inadimplência, inclusive inclusão
        em cadastros de proteção ao crédito e contratação de cobrança.
      </p>
      <p>
        9.4. A LICITOU pode alterar modalidades de pagamento, valores e datas a qualquer momento.
      </p>
      <p>
        9.5. Reajustes contratuais podem ser aplicados anualmente com base em índices econômicos.
      </p>
      <p>
        9.6. Gravação de cartão de crédito pode ser utilizada para facilitar pagamentos futuros.
      </p>
      <p>9.7. Renovação automática é opcional e pode ser cancelada a qualquer momento.</p>

      <h2>CLÁUSULA 10: VIGÊNCIA</h2>
      <p>
        10.1. Este contrato entra em vigor no momento do cadastro no PERÍODO DE TESTE ou assinatura
        e encerra-se ao final do período contratado.
      </p>
      <p>
        10.2. O USUÁRIO pode desistir do contrato em até 7 dias, com ressarcimento de valores pagos.
      </p>

      <h2>CLÁUSULA 11: RESCISÃO CONTRATUAL</h2>
      <p>
        11.1. A rescisão unilateral pode ser feita pela LICITOU, com devolução proporcional de
        valores pagos.
      </p>
      <p>11.2. Ressarcimentos são realizados pelo mesmo meio de pagamento usado na contratação.</p>

      <h2>CLÁUSULA 12: DISPOSIÇÕES GERAIS</h2>
      <p>
        12.1. Direitos e obrigações não podem ser cedidos sem consentimento expresso da LICITOU.
      </p>
      <p>12.2. A tolerância ao descumprimento contratual não implica em renúncia a direitos.</p>
      <p>
        12.3. A relação é regida pela legislação brasileira, independente do local de acesso à
        plataforma.
      </p>
      <p>12.4. Fica eleito o foro da Comarca de Curitiba (PR) para dirimir controvérsias.</p>

      <h1>CONTRATO DE LICENÇA DE USO DA PLATAFORMA LICITOU</h1>
      <p>
        Este contrato estabelece os termos e condições para o uso dos serviços disponibilizados pela
        LICITOU GESTÃO DE SOFTWARE EM LICITAÇÃO LTDA, com sede na Rua Treze de Maio, nº 336, Sala
        33; Andar 3°; Cond. Edifício Santa Maria; Centro, Curitiba - PR, CEP: 80020-270, CNPJ
        58.264.149/0001-68, referida como "LICITOU". Ao registrar-se ou utilizar nossa plataforma, a
        pessoa física ou jurídica, doravante denominada "USUÁRIO", concorda com as disposições a
        seguir, além de nossa POLÍTICA DE PRIVACIDADE disponível em licitou.com.br.
      </p>

      <h2>1. INTERPRETAÇÃO E DEFINIÇÕES</h2>
      <p>
        1.1. Interpretação: As palavras cujas letras iniciais são maiúsculas têm significados
        definidos. Estas definições se aplicam igualmente, independentemente de serem usadas no
        singular ou plural.
      </p>
      <p>1.2. Definições:</p>
      <ul>
        <li>LICITOU: Refere-se à LICITOU GESTÃO DE SOFTWARE EM LICITAÇÃO LTDA.</li>
        <li>USUÁRIO: Pessoa física ou jurídica que utiliza os serviços da LICITOU.</li>
        <li>
          PLATAFORMA: Interface digital, incluindo site e aplicativos, oferecida pela LICITOU.
        </li>
        <li>PERÍODO DE TESTE: Uso gratuito da plataforma por um período de 14 dias.</li>
        <li>ASSINATURA: Acesso pago aos serviços da LICITOU.</li>
        <li>
          DADOS PESSOAIS: Informações que identificam o USUÁRIO, conforme a Lei Geral de Proteção de
          Dados Pessoais (Lei nº 13.709/2018).
        </li>
      </ul>

      <h2>2. OBJETIVO DO CONTRATO</h2>
      <p>
        2.1. Este contrato regula o uso e o acesso à PLATAFORMA da LICITOU, permitindo ao USUÁRIO
        utilizar os recursos e conteúdos disponíveis, em conformidade com as disposições aqui
        estabelecidas.
      </p>

      <h2>3. ACESSO E USO DA PLATAFORMA</h2>
      <p>
        3.1. Requisitos de Acesso: O USUÁRIO deve garantir a disponibilidade de equipamentos
        adequados e conexão à internet para acessar a PLATAFORMA.
      </p>
      <p>
        3.2. Cadastro e Segurança: O USUÁRIO deve fornecer informações verídicas ao cadastrar-se e é
        responsável por manter a confidencialidade de suas credenciais de acesso.
      </p>
      <p>
        3.3. Uso Adequado: O USUÁRIO concorda em utilizar a PLATAFORMA para fins lícitos e conforme
        as diretrizes estabelecidas, não podendo repassar dados obtidos sem autorização.
      </p>

      <h2>4. PERÍODO DE TESTE</h2>
      <p>
        4.1. Disposições do Período de Teste: A LICITOU oferece um período gratuito de 14 dias,
        durante o qual o USUÁRIO pode explorar os serviços oferecidos. Após esse prazo, o acesso
        será suspenso até que uma assinatura seja formalizada.
      </p>
      <p>
        4.2. Limitações: Cada USUÁRIO, identificado por CNPJ, tem direito a um único período de
        teste.
      </p>

      <h2>5. ASSINATURA E PAGAMENTO</h2>
      <p>
        5.1. Condições de Assinatura: Após o PERÍODO DE TESTE, o USUÁRIO pode optar por um plano de
        assinatura disponível, respeitando os valores e métodos de pagamento estipulados.
      </p>
      <p>
        5.2. Processo de Pagamento: Os pagamentos devem ser realizados conforme acordado no momento
        da contratação. A falta de pagamento resultará na suspensão do serviço.
      </p>
      <p>
        5.3. Renovação e Cancelamento: O USUÁRIO pode optar pela renovação automática da assinatura
        e cancelá-la a qualquer momento, sem penalidades.
      </p>

      <h2>6. OBRIGAÇÕES E RESPONSABILIDADES DO USUÁRIO</h2>
      <p>
        6.1. Responsabilidade por Equipamentos: O USUÁRIO é responsável pela manutenção e custos dos
        equipamentos e softwares necessários para acessar a PLATAFORMA.
      </p>
      <p>
        6.2. Verificação de Informações: O USUÁRIO deve verificar regularmente as informações e
        atualizações fornecidas na PLATAFORMA.
      </p>
      <p>
        6.3. Segurança de Credenciais: O USUÁRIO é responsável por proteger suas credenciais de
        acesso e controlar o uso de suas senhas e chaves de API.
      </p>
      <p>
        6.4. Integridade da Infraestrutura: Qualquer tentativa de comprometer a infraestrutura de TI
        da LICITOU sujeita o USUÁRIO a sanções legais e financeiras.
      </p>
      <p>
        6.5. Uso Permitido: O USUÁRIO deve utilizar a PLATAFORMA para fins de consulta legítima,
        respeitando os limites de uso. São proibidas práticas como engenharia reversa, varredura
        massiva e manipulação de dados.
      </p>

      <h2>7. POLÍTICA DE PRIVACIDADE</h2>
      <p>
        7.1. Coleta de Dados: A LICITOU coleta e processa DADOS PESSOAIS de acordo com a legislação
        vigente, garantindo a proteção e segurança dos dados.
      </p>
      <p>
        7.2. Uso e Compartilhamento de Dados: Os dados coletados são utilizados para melhorar os
        serviços e podem ser compartilhados com parceiros, conforme permitido por lei.
      </p>
      <p>
        7.3. Segurança dos Dados: Implementamos medidas para proteger os dados do USUÁRIO contra
        acessos não autorizados.
      </p>

      <h2>8. DIREITOS DO USUÁRIO</h2>
      <p>
        8.1. Acesso e Alteração de Dados: O USUÁRIO pode solicitar acesso, correção e exclusão de
        seus DADOS PESSOAIS a qualquer momento.
      </p>
      <p>
        8.2. Consentimento e Revogação: O USUÁRIO pode revogar seu consentimento para o uso de
        dados, ciente de que isso pode impactar o uso dos serviços.
      </p>

      <h2>9. RESCISÃO</h2>
      <p>
        9.1. Rescisão por Violação: A LICITOU pode rescindir o contrato imediatamente se o USUÁRIO
        violar qualquer cláusula.
      </p>
      <p>
        9.2. Efeitos da Rescisão: Após a rescisão, o direito do USUÁRIO de acessar a PLATAFORMA
        cessa imediatamente.
      </p>

      <h2>10. LIMITAÇÃO DE RESPONSABILIDADE</h2>
      <p>
        10.1. Isenção de Garantias: A PLATAFORMA é fornecida "COMO ESTÁ", sem garantias adicionais.
      </p>
      <p>
        10.2. Limitação de Danos: A responsabilidade da LICITOU é limitada aos valores pagos pelo
        USUÁRIO pelos serviços.
      </p>

      <h2>11. DISPOSIÇÕES GERAIS</h2>
      <p>
        11.1. Alterações ao Contrato: A LICITOU pode modificar este contrato, com aviso prévio ao
        USUÁRIO, sendo que a continuidade do uso constitui aceitação das novas condições.
      </p>
      <p>
        11.2. Legislação e Foro: Este contrato é regido pelas leis brasileiras, com eleição do foro
        da Comarca de Curitiba (PR) para resolução de disputas.
      </p>
      <p>
        11.3. Separabilidade: Se qualquer cláusula for considerada inválida, as demais permanecerão
        em vigor.
      </p>
      <p>
        11.4. Contato: Para dúvidas ou esclarecimentos sobre este contrato, o USUÁRIO pode contatar
        a LICITOU através do e-mail: suporte@licitou.com.br.
      </p>

      <h2>Última Atualização</h2>
      <p>09 de dezembro de 2024</p>
    </Text>
  );
}
