import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { SectionHeading } from 'components/misc/Headings.js';
import {
  Container as ContainerBase,
  Content2Xl,
  ContentWithVerticalPadding,
} from 'components/misc/Layouts.js';
import { SectionDescription } from 'components/misc/Typography.js';
import { ReactComponent as CheckIcon } from 'feather-icons/dist/icons/check.svg';
import { useAnalytics } from 'hooks/useAnalytics';
import logoImageSrc from 'images/logo-light.svg';
import serverIllustrationImageSrc from 'images/server-illustration-2.svg';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { smoothScroll } from '../../helpers/smoothScroll';
import useAuthStore from '../../stores/authStore';
import { hasActivePlan } from '../../utils';
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLink as NavLinkBase,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
} from '../headers/Header.js';

const PrimaryBackgroundContainer = styled.div`
  ${tw`-mx-8 px-8 bg-primary-900 text-gray-100`}
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const FullHeightContent = styled(Content2Xl)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
// const NavText = tw(NavTextBase)`text-white hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)`px-4 lg:px-8`; // Add horizontal padding
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw(Column)`text-center lg:text-left lg:mr-8`; // Add right margin on larger screens
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(
  SectionDescription,
)`mt-8 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(
  PrimaryButtonBase,
)`mt-12 text-sm sm:text-base px-8 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-full max-w-sm lg:max-w-lg mx-auto`; // Adjust max-width for different screen sizes

const HighlightedText = tw.span`text-primary-500`;
const FeatureList = tw.ul`mt-8 space-y-3`;
const FeatureItem = tw.li`flex items-center text-gray-300 text-lg`;
const CheckIconWrapper = tw.span`mr-2 text-primary-500`;

export default function TwoColumnWithPrimaryBackground({
  heading = (
    <>
      Impulsione suas Vendas com o <HighlightedText>Licitou</HighlightedText>
    </>
  ),
  description = 'Economize tempo, reduza erros e aumente suas chances de sucesso em licitações públicas com nossa plataforma completa de IA.',
  primaryButtonText = 'Comece Agora Gratuitamente',
  primaryButtonUrl = '#pricing',
  imageSrc = serverIllustrationImageSrc,
  onAnchorClick = () => {},
}) {
  const { user, userData, signOut, getRedirectUrl } = useAuthStore();
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const { logAnalyticsEvent } = useAnalytics();

  React.useEffect(() => {
    getRedirectUrl().then((url) => {
      setRedirectUrl(url);
    });
  }, [getRedirectUrl, user]);

  const navigate = useNavigate();

  const handleAppRedirect = () => {
    logAnalyticsEvent('app_redirect', {
      destination: redirectUrl || 'https://app.licitou.com.br',
      user_status: user ? 'logged_in' : 'logged_out',
      location: 'hero',
    });
    if (redirectUrl) {
      if (redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        navigate(redirectUrl);
      }
    }
  };

  const handleSignOut = () => {
    logAnalyticsEvent('user_sign_out', {
      location: 'hero_section',
    });
    signOut();
    navigate('/');
  };

  const handlePrimaryButtonClick = (e) => {
    logAnalyticsEvent('hero_cta_click', {
      button_text: primaryButtonText,
      destination: primaryButtonUrl,
    });

    if (primaryButtonUrl.startsWith('#')) {
      smoothScroll(e, primaryButtonUrl);
    } else {
      navigate(primaryButtonUrl);
    }
  };

  const handleNavClick = (e, linkName) => {
    logAnalyticsEvent('nav_link_click', {
      link_name: linkName,
      location: 'hero_section',
    });
    onAnchorClick(e);
  };

  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Licitou
    </LogoLink>
  );

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#about" onClick={(e) => handleNavClick(e, 'about')}>
        Sobre
      </NavLink>
      <NavLink href="#pricing" onClick={(e) => handleNavClick(e, 'pricing')}>
        Preços
      </NavLink>
      <NavLink href="#faq" onClick={(e) => handleNavClick(e, 'faq')}>
        FAQ
      </NavLink>
      <NavLink href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>
        Contato
      </NavLink>
      {user && userData ? (
        hasActivePlan(userData) ? (
          <PrimaryLink
            href={redirectUrl || 'https://app.licitou.com.br'}
            onClick={handleAppRedirect}
          >
            Ir para Licitações
          </PrimaryLink>
        ) : (
          <PrimaryLink href="/planos" onClick={(e) => handleNavClick(e, 'planos')}>
            Escolha um Plano
          </PrimaryLink>
        )
      ) : (
        <PrimaryLink
          href={redirectUrl || 'https://app.licitou.com.br'}
          onClick={handleAppRedirect}
        >
          Ir para Licitações
        </PrimaryLink>
      )}
      {user ? (
        <>
          {/* <NavText>Bem-vindo, {user.displayName}</NavText> */}
          <NavLink onClick={handleSignOut}>Sair</NavLink>
        </>
      ) : (
        <>
          <NavLink href="/login" tw="lg:ml-12!">
            Entrar
          </NavLink>
          <PrimaryLink href="/assine">Cadastre-se</PrimaryLink>
        </>
      )}
    </NavLinks>,
  ];

  return (
    <PrimaryBackgroundContainer>
      <Header logoLink={logoLink} links={navLinks} onAnchorClick={onAnchorClick} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton as="a" href={primaryButtonUrl} onClick={handlePrimaryButtonClick}>
                {primaryButtonText}
              </PrimaryButton>
              <FeatureList>
                <FeatureItem>
                  <CheckIconWrapper>
                    <CheckIcon />
                  </CheckIconWrapper>{' '}
                  Acesso a milhares de licitações diariamente
                </FeatureItem>
                <FeatureItem>
                  <CheckIconWrapper>
                    <CheckIcon />
                  </CheckIconWrapper>{' '}
                  Análise de editais com IA
                </FeatureItem>
                <FeatureItem>
                  <CheckIconWrapper>
                    <CheckIcon />
                  </CheckIconWrapper>{' '}
                  Suporte especializado
                </FeatureItem>
              </FeatureList>
            </TextColumn>
            <IllustrationColumn>
              <Image src={imageSrc} />
            </IllustrationColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </PrimaryBackgroundContainer>
  );
}
