import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import { motion } from 'framer-motion';
import { useAnalytics } from 'hooks/useAnalytics';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js';
import logo from '../../images/logo.svg';
import useAuthStore from '../../stores/authStore.js';
import { hasActivePlan } from '../../utils';

const Header = tw.header`
  w-full px-8 py-4
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

// export const NavText = tw.span`
//   text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
//   font-semibold tracking-wide
//   text-gray-700
// `;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex justify-between items-center
  w-full
`;

const HeaderContent = tw.div`
  max-w-screen-xl mx-auto w-full
`;

export default function LightHeader({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
  onAnchorClick = () => {},
}) {
  const { user, userData, signOut, getRedirectUrl } = useAuthStore();
  const { logAnalyticsEvent } = useAnalytics();
  const [redirectUrl, setRedirectUrl] = React.useState(null);
  const navigate = useNavigate();
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();

  React.useEffect(() => {
    getRedirectUrl().then((url) => {
      setRedirectUrl(url);
    });
  }, [getRedirectUrl, user]);

  const handleSignOut = () => {
    logAnalyticsEvent('user_sign_out', {
      location: 'header',
    });
    signOut();
    navigate('/');
  };

  const handleNavToggle = () => {
    logAnalyticsEvent('mobile_nav_toggle', {
      action: showNavLinks ? 'close' : 'open',
    });
    toggleNavbar();
  };

  const handleNavClick = (e, linkName) => {
    logAnalyticsEvent('header_navigation', {
      link_name: linkName,
      is_mobile: window.innerWidth < 1024,
    });
    onAnchorClick(e);
  };

  const handleAppRedirect = () => {
    logAnalyticsEvent('app_redirect', {
      destination: redirectUrl || 'https://app.licitou.com.br',
      user_status: user ? 'logged_in' : 'logged_out',
    });
    if (redirectUrl) {
      if (redirectUrl.startsWith('http')) {
        window.location.href = redirectUrl;
      } else {
        navigate(redirectUrl);
      }
    }
  };

  const handleAuthClick = (action) => {
    logAnalyticsEvent('auth_navigation', {
      action,
      location: 'header',
    });
  };

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/#about" onClick={(e) => handleNavClick(e, 'about')}>
        Sobre
      </NavLink>
      <NavLink href="/#pricing" onClick={(e) => handleNavClick(e, 'pricing')}>
        Preços
      </NavLink>
      <NavLink href="/#faq" onClick={(e) => handleNavClick(e, 'faq')}>
        FAQ
      </NavLink>
      <NavLink href="/#contact" onClick={(e) => handleNavClick(e, 'contact')}>
        Contato
      </NavLink>
      {user && userData ? (
        hasActivePlan(userData) ? (
          <PrimaryLink
            href={redirectUrl || 'https://app.licitou.com.br'}
            tw="lg:ml-12!"
            onClick={handleAppRedirect}
          >
            Ir para Licitações
          </PrimaryLink>
        ) : (
          <PrimaryLink href="/planos" tw="lg:ml-12!" onClick={(e) => handleNavClick(e, 'planos')}>
            Escolha um Plano
          </PrimaryLink>
        )
      ) : (
        <PrimaryLink
          href={redirectUrl || 'https://app.licitou.com.br'}
          tw="lg:ml-12!"
          onClick={handleAppRedirect}
        >
          Ir para Licitações
        </PrimaryLink>
      )}
      {user ? (
        <>
          <NavLink css={roundedHeaderButton && tw`rounded-full`} onClick={handleSignOut}>
            Sair
          </NavLink>
        </>
      ) : (
        <>
          <NavLink href="/login" tw="lg:ml-12!" onClick={() => handleAuthClick('login')}>
            Entrar
          </NavLink>
          <PrimaryLink
            css={roundedHeaderButton && tw`rounded-full`}
            href="/assine"
            onClick={() => handleAuthClick('signup')}
          >
            Cadastre-se
          </PrimaryLink>
        </>
      )}
    </NavLinks>,
  ];

  const defaultLogoLink = (
    <LogoLink href="/" onClick={() => logAnalyticsEvent('logo_click', { location: 'header' })}>
      <img src={logo} alt="logo" />
      Licitou
    </LogoLink>
  );

  return (
    <Header className={className || 'header-light'}>
      <HeaderContent>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink || defaultLogoLink}
          {links || defaultLinks}
        </DesktopNavLinks>

        <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
          <MobileNavLinks
            initial={{ x: '150%', display: 'none' }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            {logoLink || defaultLogoLink}
            {links || defaultLinks}
          </MobileNavLinks>
          <NavToggle onClick={handleNavToggle} className={showNavLinks ? 'open' : 'closed'}>
            {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
          </NavToggle>
        </MobileNavLinksContainer>
      </HeaderContent>
    </Header>
  );
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakpointCss = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
