import Intercom from '@intercom/messenger-js-sdk';
import { useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useRef } from 'react';
import useAuthStore from 'stores/authStore';

const IntercomChat = () => {
  const { user, userData, company, currentProfileId } = useAuthStore();
  const { logAnalyticsEvent } = useAnalytics();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (user && userData && userData.uid && !isInitialized.current) {
      isInitialized.current = true;
      const createdAt = userData.createdAt
        ? new Date(userData.createdAt).getTime() / 1000
        : Date.now() / 1000;

      logAnalyticsEvent('intercom_init', {
        user_id: user.uid || userData.uid,
        has_company: !!company,
        has_profile: !!currentProfileId,
        type: 'authenticated',
      });

      Intercom({
        app_id: 'h0zkn1zq',
        user_id: user.uid || userData.uid,
        name: userData.name || user.displayName || '',
        email: userData.email || user.email || '',
        created_at: Math.floor(createdAt),
        company: company?.razaoSocial || '',
        custom_data: {
          cnpj: company?.cnpj || '',
          profileId: currentProfileId || '',
        },
      });
    } else if (!isInitialized.current) {
      isInitialized.current = true;
      logAnalyticsEvent('intercom_init', {
        type: 'anonymous',
      });

      Intercom({
        app_id: 'h0zkn1zq',
      });
    }
  }, [userData, user, company, currentProfileId, logAnalyticsEvent]);

  useEffect(() => {
    const handleIntercomEvent = (event) => {
      logAnalyticsEvent('intercom_event', {
        event_type: event.type,
        user_id: user?.uid || userData?.uid || 'anonymous',
      });
    };

    window.Intercom('onShow', () => handleIntercomEvent({ type: 'show' }));
    window.Intercom('onHide', () => handleIntercomEvent({ type: 'hide' }));
    window.Intercom('onUnreadCountChange', (unreadCount) =>
      handleIntercomEvent({
        type: 'unread_change',
        count: unreadCount,
      }),
    );
  }, [user, userData, logAnalyticsEvent]);

  return null;
};

export default IntercomChat;
